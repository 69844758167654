<template>
  <!---->
  <v-card>
    <v-alert type="error" border="left" v-if="!item.id">
      {{ $t('message.resource_not_available') }}
    </v-alert>

    <v-row v-if="item.id">
      <v-col cols="12" sm="12" md="12">
        <v-alert tile type="info" border="left">
          <strong>{{ item.dependency_name }}</strong>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="!item.is_finished">
        <v-img :src="item.url_download" height="300" contain> </v-img>
      </v-col>
      <v-col
        cols="12"
        :sm="!item.is_finished ? 6 : 12"
        :md="!item.is_finished ? 8 : 12"
      >
        <v-container
          class="display-2 text--primary"
          style="white-space: pre-wrap"
          >{{ item.name }}
        </v-container>
        <v-card-subtitle class="mt-1 mb-1">
          <pre style="white-space: pre-wrap">{{ item.description }}</pre>
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-divider v-if="item.id"></v-divider>

    <v-alert
      border="left"
      color="blue-grey"
      tile
      dark
      v-if="
        item.id && item.is_voting && !item.is_enrollment && !item.is_finished
      "
    >
      <strong>{{ $t('fields.votings') }}</strong>
    </v-alert>
    <v-alert
      border="left"
      color="blue-grey"
      tile
      dark
      v-if="
        item.id && !item.is_voting && item.is_enrollment && !item.is_finished
      "
    >
      <strong>{{ $t('fields.enrollments') }}</strong>
    </v-alert>
    <v-alert
      border="left"
      color="blue-grey"
      tile
      dark
      v-if="item.id && item.is_finished"
    >
      <strong>{{ $t('fields.results') }}</strong>
    </v-alert>

    <v-alert border="left" type="error" tile v-if="no_results">
      {{ $t('message.no_results') }}
    </v-alert>
    <v-alert type="success" border="left" v-if="is_finished">
      {{ $t('message.successful_process') }}
    </v-alert>

    <v-card-text
      class="pa-0 ma-0"
      v-if="
        item.id &&
        item.is_voting &&
        !item.is_enrollment &&
        !citizen_vote.id &&
        !item.is_finished
      "
    >
      <v-form ref="form" v-model="isValid">
        <v-row class="pa-0 ma-5">
          <v-col cols="12" sm="12" md="4">
            <v-select
              v-model="citizen.identification_type_id"
              :items="item.identification_types"
              :rules="formRules"
              item-text="name"
              item-value="id"
              required
            >
              <template v-slot:label>
                {{ $t('fields.identification_type') }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              v-model="citizen.identification_number"
              :rules="formRules"
              autocomplete="off"
              required
            >
              <template v-slot:label
                >{{ $t('fields.identification_number') }}
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-menu
              v-model="date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="citizen.birthday"
                  xv-on:input="$emit('v-text-field', $event)"
                  autocomplete="off"
                  required
                  v-bind="attrs"
                  :on="on"
                  :rules="formRules"
                >
                  <template v-slot:label>
                    {{ $t('fields.birthday') }} ({{ $t('fields.yyyy-mm-dd') }})
                  </template>
                  <template v-slot:append v-on="on">
                    <v-icon v-on="on"> mdi-calendar </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="citizen.birthday"
                @input="date_menu = false"
                locale="es-co"
                color="teal"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        block
        dark
        tile
        v-if="isValid"
        color="teal"
        class="mt-10"
        @click="getCitizenEnroll"
      >
        {{ $t('operations.next') }}
      </v-btn>
    </v-card-text>

    <v-card-text
      class="pa-0 ma-0"
      v-if="
        item.id &&
        item.is_voting &&
        !item.is_finished &&
        !item.is_enrollment &&
        citizen_vote.id &&
        !is_finished
      "
    >
      <v-item-group v-model="citizen_vote.voting_option_id">
        <v-container>
          <v-row justify="center">
            <v-col
              cols="12"
              sm="6"
              md="3"
              :key="index"
              v-for="(item, index) in item.options"
            >
              <v-item v-slot="{ active, toggle }" :value="item.id">
                <v-card @click="toggle">
                  <v-overlay v-if="active" absolute zIndex="1">
                    <v-icon size="100">mdi-check</v-icon>
                  </v-overlay>
                  <v-img :src="item.url_download" height="300" contain> </v-img>
                  <v-divider></v-divider>
                  <v-card-title class="title">{{ item.name }} </v-card-title>
                  <v-card-text>{{ item.description }}</v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <v-btn
        block
        dark
        tile
        v-if="citizen_vote.voting_option_id"
        color="teal"
        class="mt-10"
        @click="citizenVote"
      >
        {{ $t('operations.vote') }}
      </v-btn>
    </v-card-text>
    <v-card-text
      class="pa-0 ma-0"
      v-if="
        item.id &&
        !item.is_voting &&
        item.is_enrollment &&
        !item.is_finished &&
        !is_finished
      "
    >
      <CitizenRegistry
        ref="enrollForm"
        v-on:registry-completed="citizenEnroll"
        :requirements="item.requirements"
        :conditions="item.conditions"
        :bucket-name="item.bucket_name"
        :folder-name="item.folder_name"
      />
    </v-card-text>
    <v-card-text v-if="item.id && item.is_finished">
      <v-row dense>
        <v-col cols="12" sm="12" md="6">
          <v-card class="pa-0 ma-0" outlined tile>
            <img
              :src="item.image_abstention"
              style="height: 100%; width: 100%; object-fit: contain"
            />
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card class="pa-0 ma-0" outlined tile>
            <img
              :src="item.image_results"
              style="height: 100%; width: 100%; object-fit: contain"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CitizenRegistry from '@/components/public_forms/CitizenRegistry.vue'
export default {
  name: 'Vote',
  components: { CitizenRegistry },
  watch: {
    date_menu(val) {
      val &&
        setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR'
        })
    }
  },
  data: () => ({
    url_vote_citizen: null,
    url_vote: null,
    item: {},
    citizen: {},
    citizen_vote: {},
    formRules: [(v) => !!v || 'Required'],
    isValid: false,
    no_results: false,
    is_finished: false,
    date_menu: false
  }),

  created() {
    try {
      this.url_vote_citizen = 'votings/vote/citizen'
      this.url_vote = 'votings/vote/'
      this.getItem()
    } catch {
      this.item = {}
    }
  },
  methods: {
    getItem() {
      let url = `${this.url_vote}?id=${this.$route.params.id}`
      this.$http.get(url).then((request) => {
        this.item = request.data
      })
    },

    getCitizenEnroll() {
      let url = `${this.url_vote_citizen}?voting_id=${this.item.id}`
      url += `&identification_type_id=${this.citizen.identification_type_id}`
      url += `&identification_number=${this.citizen.identification_number}`
      url += `&birthday=${this.citizen.birthday}`
      this.no_results = false
      this.$http.get(url).then((request) => {
        this.citizen_vote = request.data
        if (Object.keys(request.data).length === 0) {
          this.no_results = true
        }
      })
    },

    citizenEnroll(citizen_id) {
      this.is_finished = false
      let data = { citizen_id: citizen_id, voting_id: this.item.id }
      this.$http.post(this.url_vote_citizen, data).then(() => {
        this.is_finished = true
      })
    },

    citizenVote() {
      this.is_finished = false
      let url = `${this.url_vote_citizen}?id=${this.citizen_vote.id}`
      this.$http
        .put(url, { voting_option_id: this.citizen_vote.voting_option_id })
        .then(() => {
          this.is_finished = true
        })
    }
  }
}
</script>

<style>
</style>