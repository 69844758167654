var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppDialogItem',{ref:"dialogItem",attrs:{"isValid":_vm.isValid}},[_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onFileChanged}}),(_vm.url_form)?_c('v-alert',{staticClass:"ma-0 px-10 py-3",attrs:{"text":"","color":"teal","dense":""}},[_c('v-toolbar',{staticClass:"float-right ma-0 pa-0",attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"teal"},on:{"click":function($event){return _vm.copyText(_vm.url_form)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"teal"},on:{"click":_vm.openForm}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1),_c('p',{staticClass:"subtitle-2 pa-0 ma-0 py-1"},[_vm._v(_vm._s(_vm.$t('fields.public_link')))]),_c('p',{staticClass:"caption pa-0 ma-0",attrs:{"id":"url_form"}},[_vm._v(_vm._s(_vm.url_form))])],1):_vm._e(),_c('v-card-text',{staticClass:"ma-0"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","width":"100px"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card-text',{staticClass:"pt-15 px-5"},[_c('v-subheader',{staticClass:"ml-n5 text-uppercase"},[_c('strong',[_vm._v(_vm._s(_vm.$t('fields.enrollments')))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","required":""},on:{"input":function($event){return _vm.$emit('v-text-field', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.start_date'))+" ")]},proxy:true}],null,true),model:{value:(_vm.item.enroll_start_date),callback:function ($$v) {_vm.$set(_vm.item, "enroll_start_date", $$v)},expression:"item.enroll_start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_1),callback:function ($$v) {_vm.menu_1=$$v},expression:"menu_1"}},[_c('v-date-picker',{attrs:{"locale":"es-co","color":"teal"},on:{"input":function($event){_vm.menu_1 = false}},model:{value:(_vm.item.enroll_start_date),callback:function ($$v) {_vm.$set(_vm.item, "enroll_start_date", $$v)},expression:"item.enroll_start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","required":""},on:{"input":function($event){return _vm.$emit('v-text-field', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.final_date'))+" ")]},proxy:true}],null,true),model:{value:(_vm.item.enroll_final_date),callback:function ($$v) {_vm.$set(_vm.item, "enroll_final_date", $$v)},expression:"item.enroll_final_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_2),callback:function ($$v) {_vm.menu_2=$$v},expression:"menu_2"}},[_c('v-date-picker',{attrs:{"locale":"es-co","color":"teal"},on:{"input":function($event){_vm.menu_2 = false}},model:{value:(_vm.item.enroll_final_date),callback:function ($$v) {_vm.$set(_vm.item, "enroll_final_date", $$v)},expression:"item.enroll_final_date"}})],1)],1)],1),_c('v-subheader',{staticClass:"ml-n5 text-uppercase"},[_c('strong',[_vm._v(_vm._s(_vm.$t('fields.votings')))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","required":""},on:{"input":function($event){return _vm.$emit('v-text-field', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.start_date'))+" ")]},proxy:true}],null,true),model:{value:(_vm.item.voting_start_date),callback:function ($$v) {_vm.$set(_vm.item, "voting_start_date", $$v)},expression:"item.voting_start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_3),callback:function ($$v) {_vm.menu_3=$$v},expression:"menu_3"}},[_c('v-date-picker',{attrs:{"locale":"es-co","color":"teal"},on:{"input":function($event){_vm.menu_3 = false}},model:{value:(_vm.item.voting_start_date),callback:function ($$v) {_vm.$set(_vm.item, "voting_start_date", $$v)},expression:"item.voting_start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","required":""},on:{"input":function($event){return _vm.$emit('v-text-field', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.final_date'))+" ")]},proxy:true}],null,true),model:{value:(_vm.item.voting_final_date),callback:function ($$v) {_vm.$set(_vm.item, "voting_final_date", $$v)},expression:"item.voting_final_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_4),callback:function ($$v) {_vm.menu_4=$$v},expression:"menu_4"}},[_c('v-date-picker',{attrs:{"locale":"es-co","color":"teal"},on:{"input":function($event){_vm.menu_4 = false}},model:{value:(_vm.item.voting_final_date),callback:function ($$v) {_vm.$set(_vm.item, "voting_final_date", $$v)},expression:"item.voting_final_date"}})],1)],1)],1)],1),_c('v-divider',{staticClass:"ma-0 pa-0 py-3"}),_c('v-card-text',{staticClass:"px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-select',{attrs:{"items":_vm.status.filter(function (i) {
                      return i.is_finished == false
                    }),"item-text":"name","item-value":"id","rules":_vm.formRules,"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.status'))+" ")]},proxy:true}]),model:{value:(_vm.item.voting_status_id),callback:function ($$v) {_vm.$set(_vm.item, "voting_status_id", $$v)},expression:"item.voting_status_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"off","rules":_vm.formRules,"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.name'))+" ")]},proxy:true}]),model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"counter":"","rows":"2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.description'))+" ")]},proxy:true}]),model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card-text',{staticClass:"pt-0 px-5"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-0",attrs:{"color":"blue-grey lighten-2 dark","dark":"","small":""},on:{"click":function($event){return _vm.uploadFile()}}},[_vm._v(_vm._s(_vm.$t('operations.select_image'))+" ")])],1)],1),_c('v-card-text',{staticClass:"ma-0 pa-0 px-15"},[(_vm.image)?_c('v-img',{attrs:{"src":_vm.image}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }