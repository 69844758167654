var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(!_vm.item.id)?_c('v-alert',{attrs:{"type":"error","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t('message.resource_not_available'))+" ")]):_vm._e(),(_vm.item.id)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-alert',{attrs:{"tile":"","type":"info","border":"left"}},[_c('strong',[_vm._v(_vm._s(_vm.item.dependency_name))])])],1),(!_vm.item.is_finished)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-img',{attrs:{"src":_vm.item.url_download,"height":"300","contain":""}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":!_vm.item.is_finished ? 6 : 12,"md":!_vm.item.is_finished ? 8 : 12}},[_c('v-container',{staticClass:"display-2 text--primary",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.item.name)+" ")]),_c('v-card-subtitle',{staticClass:"mt-1 mb-1"},[_c('pre',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.item.description))])])],1)],1):_vm._e(),(_vm.item.id)?_c('v-divider'):_vm._e(),(
      _vm.item.id && _vm.item.is_voting && !_vm.item.is_enrollment && !_vm.item.is_finished
    )?_c('v-alert',{attrs:{"border":"left","color":"blue-grey","tile":"","dark":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('fields.votings')))])]):_vm._e(),(
      _vm.item.id && !_vm.item.is_voting && _vm.item.is_enrollment && !_vm.item.is_finished
    )?_c('v-alert',{attrs:{"border":"left","color":"blue-grey","tile":"","dark":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('fields.enrollments')))])]):_vm._e(),(_vm.item.id && _vm.item.is_finished)?_c('v-alert',{attrs:{"border":"left","color":"blue-grey","tile":"","dark":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('fields.results')))])]):_vm._e(),(_vm.no_results)?_c('v-alert',{attrs:{"border":"left","type":"error","tile":""}},[_vm._v(" "+_vm._s(_vm.$t('message.no_results'))+" ")]):_vm._e(),(_vm.is_finished)?_c('v-alert',{attrs:{"type":"success","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t('message.successful_process'))+" ")]):_vm._e(),(
      _vm.item.id &&
      _vm.item.is_voting &&
      !_vm.item.is_enrollment &&
      !_vm.citizen_vote.id &&
      !_vm.item.is_finished
    )?_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"pa-0 ma-5"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.item.identification_types,"rules":_vm.formRules,"item-text":"name","item-value":"id","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.identification_type'))+" ")]},proxy:true}],null,false,1435156405),model:{value:(_vm.citizen.identification_type_id),callback:function ($$v) {_vm.$set(_vm.citizen, "identification_type_id", $$v)},expression:"citizen.identification_type_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"rules":_vm.formRules,"autocomplete":"off","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t('fields.identification_number'))+" ")]},proxy:true}],null,false,1409322181),model:{value:(_vm.citizen.identification_number),callback:function ($$v) {_vm.$set(_vm.citizen, "identification_number", $$v)},expression:"citizen.identification_number"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"xv-on:input":"$emit('v-text-field', $event)","autocomplete":"off","required":"","on":on,"rules":_vm.formRules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fields.birthday'))+" ("+_vm._s(_vm.$t('fields.yyyy-mm-dd'))+") ")]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-calendar ")])]},proxy:true}],null,true),model:{value:(_vm.citizen.birthday),callback:function ($$v) {_vm.$set(_vm.citizen, "birthday", $$v)},expression:"citizen.birthday"}},'v-text-field',attrs,false))]}}],null,false,4188553750),model:{value:(_vm.date_menu),callback:function ($$v) {_vm.date_menu=$$v},expression:"date_menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"locale":"es-co","color":"teal","max":new Date().toISOString().substr(0, 10),"min":"1950-01-01"},on:{"input":function($event){_vm.date_menu = false}},model:{value:(_vm.citizen.birthday),callback:function ($$v) {_vm.$set(_vm.citizen, "birthday", $$v)},expression:"citizen.birthday"}})],1)],1)],1)],1),(_vm.isValid)?_c('v-btn',{staticClass:"mt-10",attrs:{"block":"","dark":"","tile":"","color":"teal"},on:{"click":_vm.getCitizenEnroll}},[_vm._v(" "+_vm._s(_vm.$t('operations.next'))+" ")]):_vm._e()],1):_vm._e(),(
      _vm.item.id &&
      _vm.item.is_voting &&
      !_vm.item.is_finished &&
      !_vm.item.is_enrollment &&
      _vm.citizen_vote.id &&
      !_vm.is_finished
    )?_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-item-group',{model:{value:(_vm.citizen_vote.voting_option_id),callback:function ($$v) {_vm.$set(_vm.citizen_vote, "voting_option_id", $$v)},expression:"citizen_vote.voting_option_id"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.item.options),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-item',{attrs:{"value":item.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var active = ref.active;
    var toggle = ref.toggle;
return [_c('v-card',{on:{"click":toggle}},[(active)?_c('v-overlay',{attrs:{"absolute":"","zIndex":"1"}},[_c('v-icon',{attrs:{"size":"100"}},[_vm._v("mdi-check")])],1):_vm._e(),_c('v-img',{attrs:{"src":item.url_download,"height":"300","contain":""}}),_c('v-divider'),_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(item.name)+" ")]),_c('v-card-text',[_vm._v(_vm._s(item.description))])],1)]}}],null,true)})],1)}),1)],1)],1),(_vm.citizen_vote.voting_option_id)?_c('v-btn',{staticClass:"mt-10",attrs:{"block":"","dark":"","tile":"","color":"teal"},on:{"click":_vm.citizenVote}},[_vm._v(" "+_vm._s(_vm.$t('operations.vote'))+" ")]):_vm._e()],1):_vm._e(),(
      _vm.item.id &&
      !_vm.item.is_voting &&
      _vm.item.is_enrollment &&
      !_vm.item.is_finished &&
      !_vm.is_finished
    )?_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('CitizenRegistry',{ref:"enrollForm",attrs:{"requirements":_vm.item.requirements,"conditions":_vm.item.conditions,"bucket-name":_vm.item.bucket_name,"folder-name":_vm.item.folder_name},on:{"registry-completed":_vm.citizenEnroll}})],1):_vm._e(),(_vm.item.id && _vm.item.is_finished)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","tile":""}},[_c('img',{staticStyle:{"height":"100%","width":"100%","object-fit":"contain"},attrs:{"src":_vm.item.image_abstention}})])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","tile":""}},[_c('img',{staticStyle:{"height":"100%","width":"100%","object-fit":"contain"},attrs:{"src":_vm.item.image_results}})])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }