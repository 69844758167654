<template>
  <AppDialogItem ref="dialogItem" :isValid="isValid">
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
    <v-alert text color="teal" class="ma-0 px-10 py-3" dense v-if="url_form">
      <v-toolbar dense flat color="transparent" class="float-right ma-0 pa-0">
        <v-btn icon small color="teal" @click="copyText(url_form)">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn icon small color="teal" @click="openForm">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-toolbar>

      <p class="subtitle-2 pa-0 ma-0 py-1">{{ $t('fields.public_link') }}</p>
      <p class="caption pa-0 ma-0" id="url_form">{{ url_form }}</p>
    </v-alert>
    <v-card-text class="ma-0">
      <v-form ref="form" lazy-validation v-model="isValid" width="100px">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card-text class="pt-15 px-5">
              <v-subheader class="ml-n5 text-uppercase">
                <strong>{{ $t('fields.enrollments') }}</strong>
              </v-subheader>
              <v-row>
                <v-col cols="12" sm="5" md="6">
                  <v-menu
                    v-model="menu_1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.enroll_start_date"
                        v-on:input="$emit('v-text-field', $event)"
                        append-icon="mdi-calendar"
                        readonly
                        required
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:label>
                          {{ $t('fields.start_date') }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.enroll_start_date"
                      @input="menu_1 = false"
                      locale="es-co"
                      color="teal"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="5" md="6">
                  <v-menu
                    v-model="menu_2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.enroll_final_date"
                        v-on:input="$emit('v-text-field', $event)"
                        append-icon="mdi-calendar"
                        readonly
                        required
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:label>
                          {{ $t('fields.final_date') }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.enroll_final_date"
                      @input="menu_2 = false"
                      locale="es-co"
                      color="teal"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-subheader class="ml-n5 text-uppercase">
                <strong>{{ $t('fields.votings') }}</strong>
              </v-subheader>
              <v-row>
                <v-col cols="12" sm="5" md="6">
                  <v-menu
                    v-model="menu_3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.voting_start_date"
                        v-on:input="$emit('v-text-field', $event)"
                        append-icon="mdi-calendar"
                        readonly
                        required
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:label>
                          {{ $t('fields.start_date') }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.voting_start_date"
                      @input="menu_3 = false"
                      locale="es-co"
                      color="teal"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="5" md="6">
                  <v-menu
                    v-model="menu_4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.voting_final_date"
                        v-on:input="$emit('v-text-field', $event)"
                        append-icon="mdi-calendar"
                        readonly
                        required
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:label>
                          {{ $t('fields.final_date') }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.voting_final_date"
                      @input="menu_4 = false"
                      locale="es-co"
                      color="teal"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col> </v-row
            ></v-card-text>
            <v-divider class="ma-0 pa-0 py-3"></v-divider>
            <v-card-text class="px-5">
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-select
                    v-model="item.voting_status_id"
                    :items="
                      status.filter(function (i) {
                        return i.is_finished == false
                      })
                    "
                    item-text="name"
                    item-value="id"
                    :rules="formRules"
                    required
                  >
                    <template v-slot:label>
                      {{ $t('fields.status') }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="item.name"
                    autocomplete="off"
                    :rules="formRules"
                    required
                  >
                    <template v-slot:label>
                      {{ $t('fields.name') }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea counter rows="2" v-model="item.description">
                    <template v-slot:label>
                      {{ $t('fields.description') }}
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card-text class="pt-0 px-5">
              <v-toolbar flat color="transparent">
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey lighten-2 dark"
                  class="ma-0"
                  dark
                  small
                  @click="uploadFile()"
                  >{{ $t('operations.select_image') }}
                </v-btn>
              </v-toolbar>
            </v-card-text>
            <v-card-text class="ma-0 pa-0 px-15">
              <v-img v-if="image" :src="image"> </v-img>
            </v-card-text>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </AppDialogItem>
</template>

<script>
import AppDialogItem from '@/components/dialogs/FullScreen.vue'
export default {
  name: 'Votings',
  components: {
    AppDialogItem
  },
  props: ['url', 'status'],
  data: () => ({
    url_form: null,
    formRules: [(v) => !!v || 'Required'],
    item: {},
    image: null,
    image_file: null,
    menu_1: false,
    menu_2: false,
    menu_3: false,
    menu_4: false,
    isValid: false
  }),

  created() {},

  methods: {
    newItem(item) {
      let options = {
        color: 'teal',
        save: this.$t('operations.save')
      }
      let title = this.$t('operations.new').toUpperCase()
      this.url_form = null
      this.image = null
      this.image_file = null
      this.item = Object.assign({}, item)
      this.isNewItem = true
      return this.$refs.dialogItem.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate()) {
          var data = new FormData()
          data.append('item', JSON.stringify(this.item))
          if (this.image_file) {
            data.append('file', this.image_file)
          }
          this.$http
            .post(this.url, JSON.stringify(this.item))
            .then((response) => {
              if (this.image_file) {
                fetch(response.data.url_upload, {
                  method: 'PUT',
                  body: this.image_file
                })
              }
            })
        }
      })
    },

    editItem(item) {
      let options = {
        color: 'teal',
        save: this.$t('operations.save')
      }
      let title = `${this.$t('operations.editing').toUpperCase()} [${item.id}]`
      this.url_form = `${window.location.origin}/#/votings/vote/${item.id}`
      this.image = item.url_download
      this.image_file = null
      this.item = Object.assign(
        {},
        {
          id: item.id,
          enroll_start_date: item.enroll_start_date,
          enroll_final_date: item.enroll_final_date,
          voting_start_date: item.voting_start_date,
          voting_final_date: item.voting_final_date,
          name: item.name,
          description: item.description,
          voting_status_id: item.voting_status_id
        }
      )
      return this.$refs.dialogItem.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate())
          this.$http
            .put(`${this.url}?id=${this.item.id}`, JSON.stringify(this.item))
            .then(() => {
              if (this.image_file) {
                fetch(item.url_upload, { method: 'PUT', body: this.image_file })
              }
            })
      })
    },

    uploadFile() {
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      this.showImage = false
      this.image_file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        this.image = reader.result
        this.showImage = true
      }
      this.$refs.uploader.value = null
    },

    copyText() {
      var node = document.getElementById('url_form')
      if (document.body.createTextRange) {
        const range = document.body.createTextRange()
        range.moveToElementText(node)
        range.select()
      } else if (window.getSelection) {
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(node)
        selection.removeAllRanges()
        selection.addRange(range)
      }

      document.execCommand('copy')

      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
    },

    openForm() {
      window.open(this.url_form)
    }
  }
}
</script>

<style>
</style>