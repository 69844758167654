<template>
  <AppDialog ref="dialog">
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />

    <v-toolbar>
      <v-spacer></v-spacer>
      <v-btn color="blue-grey lighten-2 dark" class="mx-1" dark @click="newItem"
        >{{ $t('operations.new') }}
      </v-btn>
    </v-toolbar>

    <v-card class="pa-5 pt-10">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="3"
          :key="(item, index)"
          v-for="(item, index) in items"
        >
          <v-card>
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-img
                  :src="item.url_download"
                  height="300"
                  contain
                  class="grey lighten-4 pa-0"
                >
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute>
                      <v-btn @click="imageItem(item)">
                        {{ $t('operations.select_image') }}
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </template>
            </v-hover>
            <v-card-title class="title">{{ item.name }} </v-card-title>
            <v-card-text>{{ item.description }}</v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="blue-grey" text @click="editItem(item)">
                {{ $t('operations.edit') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn icon @click="deleteItem(item)"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <AppDialogForm ref="dialogForm" :isValid="isValid">
      <v-form ref="form" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="item.name"
              :rules="formRules"
              autocomplete="off"
              required
            >
              <template v-slot:label>{{ $t('fields.name') }} </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field v-model="item.description" autocomplete="off">
              <template v-slot:label>{{ $t('fields.description') }} </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <AppDialogConfirm ref="confirm" />
  </AppDialog>
</template>

<script>
import AppDialog from '@/components/dialogs/FullScreen.vue'
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
import AppDialogForm from '@/components/dialogs/Form.vue'
export default {
  name: 'TrainingCitizens',
  components: { AppDialog, AppDialogConfirm, AppDialogForm },
  data: () => ({
    url: null,
    item: {},
    items: [],
    voting_id: null,
    isSelecting: false,
    isValid: false,
    formRules: [(v) => !!v || 'Required']
  }),
  created() {
    this.name = this.$t('operations.options')
    this.url = 'votings/options/'
  },
  methods: {
    open(id) {
      this.voting_id = id
      this.getItems()
      let options = { color: 'teal' }
      let title = `${this.$t('operations.options').toUpperCase()} [${id}]`
      return this.$refs.dialog.open(title, options)
    },

    getItems() {
      this.$http
        .get(`${this.url}?voting_id=${this.voting_id}`)
        .then((response) => {
          this.items = response.data
        })
    },

    newItem() {
      this.item = {}
      let options = { color: 'teal' }
      let title = this.$t('operations.new').toUpperCase()
      this.$refs.dialogForm.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate()) {
          this.item.voting_id = this.voting_id
          this.$http
            .post(this.url, JSON.stringify(this.item))
            .then(() => this.getItems())
        }
      })
    },

    editItem(item) {
      this.item = item
      let options = { color: 'teal' }
      let title = this.$t('operations.edit').toUpperCase()
      this.$refs.dialogForm.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate()) {
          let data = {
            id: item.id,
            name: item.name,
            description: item.description,
            voting_id: item.voting_id
          }
          this.$http
            .put(
              `${this.url}?voting_id=${item.voting_id}&id=${item.id}`,
              JSON.stringify(data)
            )
            .then(() => this.getItems())
        }
      })
    },

    deleteItem(item) {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, message, options).then((confirm) => {
        if (confirm)
          this.$http
            .delete(`${this.url}?id=${item.id}`)
            .then(() => this.getItems())
      })
    },

    imageItem(item) {
      this.$refs.uploader.click()
      this.item = item
    },

    onFileChanged(e) {
      fetch(this.item.url_upload, {
        method: 'PUT',
        body: e.target.files[0]
      }).then(() => this.getItems())
      this.$refs.uploader.value = null
    }
  }
}
</script>

<style>
</style>